import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Box } from '@chakra-ui/react';
import { sortBy, mapValues } from 'lodash';
import DotMarkerProbe from './DotMarkerProbe';

const MarkerDraggable = ({ indicator, width, positive, setIsTrackHovered }) => {
  const ranked = sortBy(
    mapValues(indicator, (i, state) => ({ state, value: parseFloat(i) })),
    v => v.value
  );
  const [probeData, setProbeData] = useState({
    indicatorPosition: null,
    stateName: null,
    indicatorValue: null,
    placement: 'top',
  });
  const [isDragging, setDragging] = useState(false);
  const [position, setPosition] = useState('50%');

  const onTouchMove = e => {
    if (isDragging) {
      const x = Math.min(width, Math.max(0, e.touches[0].clientX - 70));
      setPosition(`${x}px`);
      const i = Math.round((x / width) * (ranked.length - 1));

      let placement = 'top';
      if (i < 10) placement = 'top-start';
      if (i > 40) placement = 'top-end';

      setProbeData({
        indicatorPosition: Math.abs(positive ? i - ranked.length : i + i),
        stateName: ranked[i].state,
        indicatorValue: ranked[i].value,
        placement,
      });
    }
  };
  return (
    <DotMarkerProbe isOpen={isDragging} {...probeData}>
      <Box
        h="100%"
        left={position}
        ml="-30px"
        position="absolute"
        onTouchStart={() => {
          setDragging(true);
          setIsTrackHovered(true);
        }}
        onTouchEnd={() => {
          setDragging(false);
          setIsTrackHovered(false);
          setPosition('50%');
        }}
        onTouchMove={onTouchMove}
      >
        <Icon viewBox='0 0 58.679 17.728' name="cursorArrow" w="60px" mt="calc(50% - 8px)" >
          <g>
            <g transform="translate(1334.07 407.737)">
              <g transform="translate(-1312.07 -407.737)">
                <path
                  d="M-1304.932-403.482v-.164c0-.791.011-1.583-.006-2.374a1.632,1.632,0,0,0-.927-1.5,1.738,1.738,0,0,0-1.814.081,1.708,1.708,0,0,0-.8,1.545q0,2.921,0,5.843v.172l-.113-.123a5.056,5.056,0,0,0-1.011-.911,1.785,1.785,0,0,0-1.274-.293,1.316,1.316,0,0,0-1.161,1.075,2.239,2.239,0,0,0,.166,1.285,22.692,22.692,0,0,0,1.207,2.566,18.979,18.979,0,0,0,2.253,3.451,9.575,9.575,0,0,1,.6.808,2.436,2.436,0,0,1,.455,1.367.572.572,0,0,0,.655.645h6.962a.572.572,0,0,0,.651-.648,9.3,9.3,0,0,1,.192-1.9c.174-.808.37-1.612.559-2.417a13.379,13.379,0,0,0,.408-2.68c.023-.8.022-1.6.02-2.4a1.735,1.735,0,0,0-2.019-1.74c-.133.019-.263.055-.4.085a1.776,1.776,0,0,0-1.019-1.148,1.634,1.634,0,0,0-1.516.082A1.736,1.736,0,0,0-1304.932-403.482Z"
                  transform="translate(1312.07 407.737)"
                  fill="#fff"
                />
                <path
                  d="M-1304.932-403.482a1.736,1.736,0,0,1,2.071.7,1.634,1.634,0,0,1,1.516-.082,1.776,1.776,0,0,1,1.019,1.148c.139-.03.269-.066.4-.085a1.735,1.735,0,0,1,2.019,1.74c0,.8,0,1.6-.02,2.4a13.379,13.379,0,0,1-.408,2.68c-.189.8-.385,1.608-.559,2.417a9.3,9.3,0,0,0-.192,1.9.572.572,0,0,1-.651.648h-6.962a.572.572,0,0,1-.655-.645,2.436,2.436,0,0,0-.455-1.367,9.575,9.575,0,0,0-.6-.808,18.979,18.979,0,0,1-2.253-3.451,22.692,22.692,0,0,1-1.207-2.566,2.239,2.239,0,0,1-.166-1.285,1.316,1.316,0,0,1,1.161-1.075,1.785,1.785,0,0,1,1.274.293,5.056,5.056,0,0,1,1.011.911l.113.123v-.172q0-2.921,0-5.843a1.708,1.708,0,0,1,.8-1.545,1.738,1.738,0,0,1,1.814-.081,1.632,1.632,0,0,1,.927,1.5c.017.791.005,1.583.006,2.374Zm4.656,12.286c.032-.32.041-.634.1-.938.177-.912.361-1.823.567-2.729a14.856,14.856,0,0,0,.465-2.422c.053-.932.044-1.868.057-2.8a.506.506,0,0,0-.236-.45.625.625,0,0,0-.644-.034.535.535,0,0,0-.3.514c0,.209,0,.418,0,.627a.541.541,0,0,1-.418.54.576.576,0,0,1-.761-.587c0-.582,0-1.165,0-1.747a.549.549,0,0,0-.409-.572.578.578,0,0,0-.771.588c0,.39,0,.779,0,1.169a.545.545,0,0,1-.411.558.578.578,0,0,1-.768-.591c0-.582,0-1.165,0-1.747a.546.546,0,0,0-.414-.569.578.578,0,0,0-.766.593c0,.39,0,.779,0,1.169a.543.543,0,0,1-.416.554.577.577,0,0,1-.764-.6q0-2.577,0-5.154c0-.057,0-.115,0-.172a.553.553,0,0,0-.538-.549.56.56,0,0,0-.633.485,1.315,1.315,0,0,0-.007.2q0,4.33,0,8.66c0,.049,0,.1,0,.148a.557.557,0,0,1-.59.548.555.555,0,0,1-.588-.549c-.006-.143,0-.287,0-.43a.371.371,0,0,0-.021-.131,5.637,5.637,0,0,0-1.471-2.109,1.606,1.606,0,0,0-.474-.3c-.322-.123-.485.017-.425.35a3.161,3.161,0,0,0,.165.6,25.77,25.77,0,0,0,1.715,3.4,15.2,15.2,0,0,0,1.893,2.614,3.554,3.554,0,0,1,.737,1.154c.084.229.138.469.208.712Z"
                  transform="translate(1312.07 407.737)"
                  fill="#184595"
                  fillRule="evenodd"
                />
                <path
                  d="M-1255.339-343.891h-5.93c-.07-.243-.124-.483-.208-.712a3.552,3.552,0,0,0-.737-1.154,15.21,15.21,0,0,1-1.893-2.614,25.769,25.769,0,0,1-1.715-3.4,3.16,3.16,0,0,1-.165-.6c-.06-.333.1-.473.425-.35a1.607,1.607,0,0,1,.474.3,5.636,5.636,0,0,1,1.471,2.109.366.366,0,0,1,.021.131c0,.143,0,.287,0,.43a.555.555,0,0,0,.588.549.557.557,0,0,0,.59-.548c0-.049,0-.1,0-.148q0-4.33,0-8.66a1.315,1.315,0,0,1,.007-.2.56.56,0,0,1,.633-.485.553.553,0,0,1,.538.549c0,.057,0,.115,0,.172q0,2.577,0,5.154a.577.577,0,0,0,.764.6.543.543,0,0,0,.416-.554c0-.39,0-.779,0-1.169a.578.578,0,0,1,.766-.593.546.546,0,0,1,.414.569c0,.582,0,1.165,0,1.747a.578.578,0,0,0,.768.591.545.545,0,0,0,.411-.558c0-.39,0-.779,0-1.169a.578.578,0,0,1,.771-.588.548.548,0,0,1,.409.572c0,.582,0,1.165,0,1.747a.576.576,0,0,0,.761.587.541.541,0,0,0,.418-.54c.005-.209,0-.418,0-.627a.535.535,0,0,1,.3-.514.625.625,0,0,1,.644.034.506.506,0,0,1,.236.45c-.012.934,0,1.87-.057,2.8a14.856,14.856,0,0,1-.465,2.422c-.206.906-.39,1.817-.567,2.729C-1255.3-344.525-1255.307-344.211-1255.339-343.891Z"
                  transform="translate(1267.134 360.432)"
                  fill="#fff"
                  fillRule="evenodd"
                />
              </g>
            </g>
            <g transform="translate(-50 -121)">
              <line
                x2="13"
                transform="translate(56.5 130.5)"
                fill="none"
                stroke="#fff"
                strokeWidth="1"
              />
              <path d="M0,0V9L7,4.5Z" transform="translate(57 135) rotate(180)" fill="#fff" />
              <g>
                <path
                  d="M31.817,0H45.679"
                  transform="translate(56.5 130.5)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1"
                />
                <path d="M0,0V9L7,4.5Z" transform="translate(101.679 126)" fill="#fff" />
              </g>
            </g>
          </g>
        </Icon>
      </Box>
    </DotMarkerProbe>
  );
};

MarkerDraggable.propTypes = {
  indicator: PropTypes.PropTypes.shape(),
  width: PropTypes.number,
  positive: PropTypes.string,
  setIsTrackHovered: PropTypes.func,
};

export default MarkerDraggable;
